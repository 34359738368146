import React from 'react'

const Logo = () => {
    return (
        <svg className="logo" width="133" height="40" viewBox="0 0 133 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M82.9151 0.0312514C81.9994 0.0305863 81.0997 0.267294 80.3066 0.71753C79.5123 1.16797 78.8526 1.81627 78.3939 2.59719C77.9352 3.3781 77.6937 4.26408 77.6937 5.16597C77.6937 6.06786 77.9352 6.95384 78.3939 7.73476C78.8526 8.51567 79.5123 9.16397 80.3066 9.61442L98.5943 19.9951L80.3035 30.3821C79.7094 30.7194 79.1887 31.1686 78.771 31.7039C78.3534 32.2392 78.047 32.8502 77.8695 33.5019C77.6919 34.1537 77.6466 34.8335 77.7361 35.5025C77.8256 36.1715 78.0482 36.8166 78.3911 37.401C78.7341 37.9853 79.1907 38.4976 79.7349 38.9084C80.2791 39.3191 80.9003 39.6205 81.5629 39.7952C82.2255 39.9698 82.9166 40.0144 83.5967 39.9264C84.2768 39.8383 84.9326 39.6194 85.5267 39.282L88.814 37.4272C88.372 36.6755 88.139 35.8226 88.1383 34.9542V34.7409C88.137 33.8714 88.3688 33.017 88.8102 32.2637C89.2516 31.5104 89.887 30.8848 90.6525 30.4501L109.041 20.0106L90.6557 9.57732C89.8906 9.14285 89.2555 8.5178 88.8141 7.76509C88.3727 7.01237 88.1407 6.15857 88.1415 5.28963V5.07323C88.1411 4.20469 88.3742 3.35148 88.8172 2.60016L85.5299 0.745352C84.7355 0.295405 83.8349 0.058738 82.9182 0.0590735" fill="#C9DC50"/>
        <path d="M88.8141 2.58398C88.3711 3.33531 88.138 4.18851 88.1384 5.05706V5.27345C88.1376 6.1424 88.3696 6.9962 88.811 7.74891C89.2524 8.50162 89.8876 9.12668 90.6526 9.56115L109.038 19.9944L90.6495 30.4339C89.884 30.8686 89.2485 31.4942 88.8071 32.2475C88.3657 33.0008 88.134 33.8552 88.1353 34.7247V34.938C88.1359 35.8064 88.3689 36.6593 88.8109 37.4111L101.036 30.4648L101.184 30.3752L119.475 19.9913L101.197 9.61679L101.096 9.55806L101.046 9.53023L88.8141 2.58398Z" fill="#54B948"/>
        <path d="M101.039 30.4746L88.814 37.4209C89.2546 38.1729 89.8886 38.7977 90.6525 39.2324L90.8442 39.3375C91.6086 39.7716 92.4757 40.0002 93.3584 40.0002C94.241 40.0002 95.1082 39.7716 95.8726 39.3375L99.2762 37.4054C98.5987 36.2515 98.4033 34.8838 98.731 33.5909C99.0587 32.2979 99.8841 31.1806 101.033 30.4746" fill="#74C046"/>
        <path d="M93.3618 0C92.479 0.000602065 91.6118 0.229832 90.8476 0.664639L90.6559 0.772836C89.8921 1.20753 89.258 1.8323 88.8174 2.58436L101.046 9.54298C99.8946 8.839 99.0669 7.72214 98.7379 6.42866C98.4089 5.13517 98.6043 3.76651 99.2827 2.61219L95.8791 0.680096C95.1149 0.245288 94.2478 0.0160591 93.3649 0.0154571" fill="#74C046"/>
        <path d="M103.818 0.03125C102.902 0.0313113 102.003 0.267969 101.209 0.717529C100.416 1.16747 99.7572 1.81457 99.2984 2.59397L126.885 18.2733C127.194 18.4481 127.451 18.6999 127.629 19.0033C127.807 19.3066 127.901 19.6509 127.901 20.0013C127.901 20.3518 127.807 20.696 127.629 20.9994C127.451 21.3028 127.194 21.5546 126.885 21.7294L99.2827 37.4056C99.6255 37.99 100.082 38.5022 100.626 38.913C101.17 39.3238 101.791 39.625 102.454 39.7996C103.116 39.9741 103.807 40.0185 104.487 39.9303C105.167 39.842 105.823 39.6228 106.417 39.2851L129.315 26.2829C130.435 25.6463 131.366 24.7309 132.013 23.6285C132.66 22.5261 133 21.2757 133 20.0029C133 18.73 132.66 17.4796 132.013 16.3773C131.366 15.2749 130.435 14.3594 129.315 13.7228L106.417 0.717529C105.626 0.26967 104.73 0.0330765 103.818 0.03125Z" fill="#2DBDB6"/>
        <path d="M99.2824 2.59668C98.599 3.75241 98.4009 5.12474 98.7302 6.4219C99.0594 7.71907 99.89 8.83876 101.045 9.54293L101.096 9.57075L119.484 20.0133L101.045 30.4868C99.8965 31.1928 99.0712 32.3101 98.7435 33.6031C98.4158 34.896 98.6112 36.2636 99.2887 37.4176L126.885 21.729C127.194 21.5542 127.45 21.3024 127.629 20.999C127.807 20.6956 127.901 20.3514 127.901 20.0009C127.901 19.6505 127.807 19.3063 127.629 19.0029C127.45 18.6995 127.194 18.4477 126.885 18.2729L99.2824 2.59668Z" fill="#20A787"/>
        <path d="M67.748 14.3964V16.6716C66.7832 15.3454 64.9949 14.1367 62.3896 14.1367C58.2569 14.1367 54.979 17.3332 54.979 22.3195C54.979 27.3058 58.2569 30.6167 62.3582 30.6167C64.9384 30.6167 66.7832 29.408 67.748 28.023V30.357H71.8807V14.3964H67.748ZM63.4424 27.0709C61.2425 27.0709 59.1651 25.3397 59.1651 22.3164C59.1651 19.2931 61.2456 17.6794 63.4424 17.6794C65.6989 17.6794 67.748 19.3518 67.748 22.3752C67.748 25.3985 65.6989 27.074 63.4424 27.074V27.0709Z" fill="#474848"/>
        <path d="M45.9911 25.4021C45.9911 29.0622 48.0685 30.3482 51.1735 30.3482H53.7537V26.9477H51.8492C50.5607 26.9477 50.1207 26.4871 50.1207 25.4206V17.6922H53.7537V14.3969H50.1175V10.4492H45.9911V14.3969H44.0552V17.7077H45.9911V25.4021Z" fill="#474848"/>
        <path d="M41.8269 9.03613H37.7256V30.3571H41.8269V9.03613Z" fill="#474848"/>
        <path d="M35.4158 22.032C35.4158 17.2775 32.1034 14.1367 27.4175 14.1367C22.6154 14.1367 19.2778 17.3332 19.2778 22.3752C19.2778 27.4171 22.7066 30.6167 27.4207 30.6167C31.434 30.6167 34.1305 28.2827 35.0073 25.3428H30.5917C30.0637 26.4094 29.0958 27.2749 27.339 27.2749C25.2899 27.2749 23.6494 25.9487 23.4451 23.587H35.3058C35.3861 23.0725 35.4229 22.5524 35.4158 22.032ZM23.4734 20.8789C23.7877 18.6872 25.359 17.4506 27.3107 17.4506C29.4195 17.4506 31.1197 18.7737 31.1763 20.8789H23.4734Z" fill="#474848"/>
        <path d="M12.7438 9.03646V16.6133C11.6282 15.1171 9.57909 14.1403 7.44203 14.1403C3.28103 14.1403 0 17.3367 0 22.3231C0 27.3094 3.28103 30.6202 7.4106 30.6202C9.95937 30.6202 11.7759 29.4671 12.7533 27.9988V30.3605H16.9111V9.03027L12.7438 9.03646ZM8.46656 27.0744C6.26664 27.0744 4.18928 25.3433 4.18928 22.32C4.18928 19.2966 6.26978 17.6829 8.46656 17.6829C10.7199 17.6829 12.7721 19.3554 12.7721 22.3787C12.7721 25.402 10.7199 27.0744 8.46656 27.0744Z" fill="#474848"/>
        </svg>
    )
}

export default Logo